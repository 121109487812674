import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { SelectOrderTypePage } from '../select-order-type/select-order-type.page';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.page.html',
  styleUrls: ['./popup.page.scss'],
})
export class PopupPage implements OnInit {
  @Input() currentUI: string = '';

  constructor(public modalController: ModalController, public appService: AppServiceService) {

  }

  ngOnInit() {
  }

  public async langSelect(event) {
    await this.appService.changeLanguage(event);
    // this.dismiss();
  }


  public dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }

  /**
   * Choose favourite restaurant
   */
  async chooseFavouriteRestaurant() {
    this.dismiss();

    let componentProps = {
      'currentUI': 'order-type',
      'selectFavouriteRestaurant': true
    }

    const cartModal = await this.modalController.create({
      component: SelectOrderTypePage,
      cssClass: 'custom-popup',
      componentProps: componentProps
    });

    await cartModal.present();
  }

}
